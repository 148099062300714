<template>
    <section class="start" :class="{pinned: view.start.pinned}">
      <input class="pin" @click="this.view.start.pinned = !this.view.start.pinned" type="button" v-bind:value="this.view.start.pinned ? 'Unpin' : 'Pin'">

      <h2>Create new run:</h2>

      <!--        <section class="server-control">-->
      <!--          <span class="server-status">Server Status: {{server.status}} {{server.statusprogress}}</span>-->
      <!--          <input ref="serverstartstop" @click="startStopServer()" type="button" v-bind:value="server.status == 'on' ? 'Power off Server' : 'Power on Server'">-->
      <!--        </section>-->
      <!--        <input v-bind:disabled="server.status == 'on' ? 'false' : 'true'" v-bind:style="'background-color: '+this.runStartedColor" ref="startrun" @click="checkAmount" type="button" v-bind:value="this.runStarted"><input v-if="runStartedCount == 1" type="button" @click="cancelRunStart()" value="Cancel">-->

      <h3>Rate:</h3>

      <section class="rate">
        <span class="label">Kurs auswählen:</span>
        <select ref="currency">
          <option value="ETH">ETH</option>
          <option value="BTC">BTC</option>
          <option value="DOGE">DOGE</option>
          <option value="SOL">SOL</option>
        </select>
      </section>

      <h3>Time Range:</h3>

      <section class="time">
        <span class="label">Von:</span><input v-model="status.fromTime" type="datetime">
        <span class="label">Bis:</span> <input v-model="status.toTime" type="datetime">
      </section>

      <section class="save-time">
        <input @click="removeTime()" type="button" value="Delete Time Range">
        <select class="save-time-load" ref="timesLoad" @focus="loadTimes()" @change="setTime($event)">
          <option v-bind:value="key" :key="key" v-for="(parameter, key) in save.times">{{parameter.name}}</option>
        </select>
        <input class="save-time-name" ref="saveTimeName" type="text" value="Name"><input @click="saveTime()" type="button" value="Save Time Range">
      </section>

      <section>
        <span class="label">Points:</span><span>{{ pointsAsNumber }} [ {{ pointsAsTime }} ]</span>
      </section>

      <h3>Parameters:</h3>
      <input type="button" @click="this.withDescription = !this.withDescription" v-bind:value="this.withDescription ? 'Paramter Info ausblenden' : 'Parameter Info einblenden'">
      <header style="text-align: left; padding-left: 2em;">Von - Schrittweite - Bis</header>
      <ul class="parameters">
        <li v-bind:id="parameter" ref="parameters" :key="parameter" v-for="parameter in parameters">
          {{parameter.name}}
          <div v-if="withDescription" class="info">
            <div class="description">{{parameter.info}}</div>
            <ul class="possible-values">
              <li v-if="parameter.range.decimal">Dezimalen werden Empfohlen.</li>
              <li v-if="parameter.range.decimal === false">Nur Ganze Zahlen verwenden.</li>
              <li v-if="parameter.range.zeroIsOff">Bei 0 wird der Teil nicht benutzt.</li>
            </ul>
          </div>
          <div class="numbers">
            <input class="select from" type="number" v-model="status.from[parameter.name]">
            <input class="select step" type="number" v-model="status.step[parameter.name]">
            <input class="select to" type="number" v-model="status.to[parameter.name]">
            <section class="helper">
              |
              <input type="button" value="0" @click="setRow(0, parameter.name)">
              <input type="button" value="1" @click="setRow(1, parameter.name)">
            </section>
          </div>
        </li>
      </ul>

      <section class="save-parameters">
        <input @click="removeParameters()" type="button" value="Delete Parameters">
        <select class="save-parameters-load" ref="settingsLoad" @focus="loadSettings()" @change="setSettings($event)">
          <option v-bind:value="key" :key="key" v-for="(parameter, key) in save.parameters">{{parameter.name}}</option>
        </select>
        <input class="save-parameters-name" ref="saveName" type="text" value="Name"><input @click="saveSettings()" type="button" value="Save Parameters">
      </section>

      <section>
        <span class="label">Amount:</span><span>{{ amountOfSelect }}</span>
      </section>

      <section class="summary">
        <hr>

        <section>
          <span class="label">Amount:</span><span>{{ amountOfSelect }}</span>
          <span class="label">Points:</span><span>{{ pointsAsNumber }} [ {{ pointsAsTime }} ]</span>
        </section>

        <section class="start-buttons">
          <input v-bind:style="'background-color: '+this.runStartedColor" ref="startrun" @click="checkAmount" type="button" v-bind:value="this.runStarted">
          <input v-if="runStartedCount == 1" type="button" @click="cancelRunStart()" value="Cancel">
        </section>
      </section>
    </section>

    <section class="progress" :class="{pinned: view.progress.pinned}">
      <input class="pin" @click="this.view.progress.pinned = !this.view.progress.pinned" type="button" v-bind:value="this.view.progress.pinned ? 'Unpin' : 'Pin'">
      <section class="progress-lists">
        <section class="queue-list">
          <ResultRun :key="run.original" kind="queued" v-for="run in runs.queued" v-bind:run="run" v-bind:parameters="parameters"></ResultRun>
        </section>
        <section class="run-list">
          <ResultRun :key="run.original" kind="run" v-for="run in runs.running" v-bind:run="run" v-bind:parameters="parameters"></ResultRun>
        </section>
      </section>
      <section class="selected-progress">
<!--        <ResultRun kind="run" v-bind:run="status.name" v-bind:parameters="parameters"></ResultRun>-->
      </section>
    </section>

    <section class="filter" :class="{pinned: view.filters.pinned}">
      <h3>Ordering:</h3>
      <section class="sort">
        <span @click="activateSort(index)" :key="sort.name" v-for="(sort, index) in filter.sortableValues">{{sort.name}}{{sort.value === filter.sortBy ? (filter.sortDirection ? "+" : "-") : ""}}</span>
      </section>

      <h3>Filter:</h3>
      <ul class="filters">
        <li :key="filter.name" v-for="(filter, index) in filter.filters">
          <span @click="deActivateFilter(index)">{{filter.name}}:</span>
          <template v-if="filter.type == 'number'">
            <select @change="setFilterAddition($event, index)">
              <option value="greater" > &gt;</option>
              <option value="same"> = </option>
              <option value="lower"> &lt; </option>
            </select>
          </template>
          <input @change="setFilter($event, index)" value="" type="text">
        </li>
      </ul>

      <h3>Labels:</h3>
      <input class="pin" @click="this.view.filters.pinned = !this.view.filters.pinned" type="button" v-bind:value="this.view.filters.pinned ? 'Unpin' : 'Pin'">
      <input type="button" value="Show all" v-if="this.filter.selectedLabel" @click="this.filter.selectedLabel = ''">
      <ul class="labels">
        <li @drop="onDrop($event, label)" @dragenter.prevent @dragover.prevent class="label" :key="label.id" v-for="label in filter.labels">
          <span :class="{selected: this.filter.selectedLabel === label}" @click="this.filter.selectedLabel = label">{{label.name}}</span><AreYouSure text="Remove" v-bind:function="removeLabel.bind(this, label)"></AreYouSure>
          <ul>
            <li :key="runId" v-for="runId in label.runs">{{ this.runs.done.find(run => run.id == runId).name == '' ? this.runs.done.find(run => run.id == runId).original : this.runs.done.find(run => run.id == runId).name }} <span @click="disconnectLabel({id: runId}, label)">Remove</span></li>
          </ul>
        </li>
      </ul>
      <section class="label-buttons">
        <input type="text" class="label-create" ref="labelName" value="Name">
        <input type="button" @click="createLabel()" value="Create Label">
      </section>
    </section>

    <section class="result">
      <ResultRun :key="run.original" v-for="run in selectedFilterSortedDone" v-bind:run="run" kind="done" v-bind:parameters="parameters"></ResultRun>
    </section>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import ResultRun from "@/components/ResultRun";
import AreYouSure from "@/components/AreYouSure";

Object.byString = function(o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

function calc_amount(from, step, to) {
  let amount = 1
  let part = 0
  for (let key in from) {
    // console.log(key)
    if (step[key] !== 0 && to[key] - from[key] !== 0) {
      part = Math.round((to[key] - from[key]) / step[key])
      part++
    } else {
      part = 1
    }
    // console.log(amount)
    amount *= part
  }

  return amount
}

// function settings(settings) {
//   let float_settings = settings.split(", ").map(char => parseFloat(char))
//
//   return float_settings
// }
//
// function settingsaverages(settings) {
//   let float_settings = settings.split(" ").map(char => parseFloat(char))
//
//   return float_settings
// }

export default {
  name: "StartRun",
  components: {ResultRun, AreYouSure},
  props: {
    parameters: [String],
  },
  watch: {
    status: {
      handler(newValue, oldValue) {
        console.log("save select step:", newValue, oldValue)
        console.log(this.status.from)
      },
      deep: true
    },
  },
  beforeMount() {
    this.initialize()

    this.filter.sortableValues = [
      {name: "Name", value: "original"},
      // {name: "Amount", value: "meta.amount"},
      {name: "Created", value: "created"}
    ]

    this.filter.filters = [
      // {name: "Amount", access: "meta.amount", type: "number", compare: "", active: false},
      // {name: "Points", access: "meta.points", type: "number", compare: "", active: false},
      {name: "Original Name", access: "original", type: "string", active: false},
    ]
  },
  computed: {
    selectedDone() {
      if (this.filter.selectedLabel == "") {
        return this.runs.done
      } else {
        return this.runs.done.filter(x => {
          console.log("selected label content:", this.filter.labels[this.filter.selectedLabel])

          if (this.filter.labels[this.filter.selectedLabel].find(y => y.original === x.name.original)) {
            return true
          }

          return false
        })
      }
    },
    selectedFilterDone() {
      if (this.filter.filters.filter(z => {
        return z.active == true
      }).length < 1) {
        console.log("filtersorteddone zu klein")
        return this.selectedDone
      }

      return this.selectedDone.filter(x => {
        let doOrnot = false

        this.filter.filters.filter(y => {
          console.log("filtersorteddone active")
          return y.active == true
        }).forEach(filter => {
          if (filter.type == 'number') {
            console.log("filtersorteddone number")
            console.log("filter.compare:", filter.compare)
            if ('test' == 'test' || filter.compare == 'greater') {
              console.log("filtersorteddone greater")
              console.log("filter.access", filter.access, " ", Object.byString(x, filter.access))
              // console.log("amount", filter.access, " ", x.amount)
              if (Object.byString(x, filter.access) > filter.value) {
                console.log("filtersorteddone success")
                console.log("true")
                doOrnot = true
              }
            } else if (filter.compare == 'same') {
              console.log("filtersorteddone same")
              if (Object.byString(x, filter.access) > filter.value) {
                doOrnot = true
              }
            } else if (filter.compare == 'less') {
              console.log("filtersorteddone less")
              if (Object.byString(x, filter.access) > filter.value) {
                doOrnot = true
              }
            }
          } else if (filter.type == 'string') {
            console.log("filtersorteddone tsring")
            if (Object.byString(x, filter.access).includes(filter.value)) {
              doOrnot = true
            }
          }
        })

        console.log("false")
        return doOrnot
      })
    },
    selectedFilterSortedDone() {
      let result = this.selectedFilterDone

      if (this.filter.sortBy) {
        console.log("filterSortedDone:", this.filter.sortBy)
        // console.log("filterSortedDone:", result[0])

        // console.log("filterSortedDone:", Object.byString(result[0], this.sortBy))

        if (this.filter.sortBy == "created") {
          result.sort((a, b) => (new Date(Object.byString(a, this.filter.sortBy)).getTime() > new Date(Object.byString(b, this.filter.sortBy)).getTime()) ? 1 : (((new Date(Object.byString(a, this.filter.sortBy)).getTime() < new Date(Object.byString(b, this.filter.sortBy)).getTime()) ? -1 : 0)))
        } else {
          result.sort((a, b) => (Object.byString(a, this.filter.sortBy) > Object.byString(b, this.filter.sortBy)) ? 1 : (((Object.byString(a, this.filter.sortBy) < Object.byString(b, this.filter.sortBy)) ? -1 : 0)))
        }
      }

      return (this.filter.sortDirection ? result : result.reverse())

    },
    amountOfSelect() {
      return calc_amount(this.status.from, this.status.step, this.status.to)
    },
    pointsAsNumber() {
      let fromTime = moment(this.status.fromTime)
      let toTime = moment(this.status.toTime)

      let duration = moment.duration(toTime.diff(fromTime))

      return duration.asHours()
    },
    pointsAsTime() {
      let fromTime = moment(this.status.fromTime)
      let toTime = moment(this.status.toTime)

      let duration = moment.duration(toTime.diff(fromTime))

      console.log("Duration of pointsAsTime:")
      console.log(duration)

      return (duration.years() > 0 ? duration.years() + ' years ' : '')
          + (duration.months() > 0 ? duration.months() + ' months ' : '')
          + (duration.weeks() > 0 ? duration.weeks() + ' weeks ' : '')
          + (duration.days() > 0 ? duration.days() + ' days ' : '')
          + (duration.hours() > 0 ? duration.hours() + ' hours ' : '')
    }
  },
  data() {
    return {
      // amountLimit: 13_000_000,
      withDescription: true,
      amountLimit: 50_000_000,
      runStarted: "Start run",
      runStartedCount: 0,
      runStartedColor: "white",
      save: {
        parameters: [{}],
        times: [{}],
      },
      status: {
        lastRunName: "",
        name: "",

        from: {},
        to: {},
        step: {},
        fromTime: "2014-07-10T23:00:00+00:00",
        toTime: "2024-03-13T23:00:00+00:00",
      },
      filter: {
        labels: [],
        sortableValues: [], /* For "sortBy. */
        sortBy: "meta.name.original",
        sortDirection: true,
        filters: [], /* For compare "filters". */
        selectedLabel: "",
      },
      server: {
        status: "off",
        statusprogress: "",
      },
      view: {
        start: {
          pinned: false,
        },
        progress: {
          pinned: false,
        },
        filters: {
          pinned: false,
        }
      },
      runs: {
        queued: [],
        running: [],
        done: [],
      }
    }
  },
  mounted() {
    for (let parameter of this.parameters) {
      console.log(parameter.name, "mounted")
      this.status.from[parameter.name] = 1
      this.status.step[parameter.name] = 1
      this.status.to[parameter.name] = 1
    }

    let ws = new WebSocket( (location.protocol == 'http:' ? 'ws://' : 'wss://') + location.host + '/client');

    ws.onopen = function() {
      console.log("Websocket Connection established.");
      // console.log("Sending to server");
      // ws.send("My name is John");
    };

    const vueComponentInstance = this;

    ws.onmessage = function(event) {
      console.log("Websocket Data Received: " + event.data);

      const message = event.data.split(":-:");
      const messageType = message[0];
      const messageBody = JSON.parse(message[1]);

      let run = {}

      switch (messageType) {
        case "Queue":
          vueComponentInstance.runs.queued.push(messageBody);
        break
        case "Running":
          run = vueComponentInstance.runs.queued.find(item => {
            return item.original == messageBody.original
          });

          run.progress = {};

          vueComponentInstance.runs.running.push(run);

          vueComponentInstance.runs.queued = vueComponentInstance.runs.queued.filter(item => {
            return item.original != messageBody.original
          });
        break
        case "Progress":
          vueComponentInstance.runs.running.find(item => {
            return item.original == messageBody.original
          }).progress[messageBody.kind] = messageBody.value;
        break
        case "Done":
          // eslint-disable-next-line no-case-declarations
          let test = vueComponentInstance.runs.running.find(item => {
            return item.original == messageBody.original
          });

          test.results = messageBody;

          vueComponentInstance.runs.done.push(test);

          vueComponentInstance.runs.running = vueComponentInstance.runs.running.filter(item => {
            return item.original != messageBody.original
          });

          // vueComponentInstance.runs.done.find(item => {
          //   return item.original ==  messageBody.original
          // }).results = messageBody;
        break
      }
    };

    ws.onclose = function(event) {
      if (event.wasClean) {
        console.log(`Websocket Connection Closed cleanly, code=${event.code} reason=${event.reason}.`);
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        console.log('Websocket Connection died.');
      }
    };

    ws.onerror = function() {
      console.log("Websocket Error.");
    };

    // this.getServerStatus()
  },
  methods: {
    initialize() {
      axios.get("initialize").then(response => {
        console.log(response.data.runs);
        this.runs = response.data.runs;
      })

      this.loadLabels()
    },
    setRow(value, parameter) {
      this.status.from[parameter] = value
      this.status.step[parameter] = value
      this.status.to[parameter] = value
    },
    activateSort(index) { /* "sortBy" and "direction */
      if (this.filter.sortableValues[index].value == this.filter.sortBy) {
        this.filter.sortDirection = !this.filter.sortDirection

        console.log("Sort reverse:", this.filter.sortableValues[index].name)
      } else {
        this.filter.sortDirection = true
      }

      console.log("Sort:", this.filter.sortableValues[index].name)

      this.filter.sortBy = this.filter.sortableValues[index].value
    },
    deActivateFilter(index) { /* comparable "filters" */
      this.filter.filters[index].active = !this.filter.filters[index].active
      console.log("deactivate filter")
    },
    setFilter(event, index) {
      console.log("Runs done:");
      console.log(this.runs.done);
      console.log("set filter index:", index)
      if (event.target.value == "") {
        this.filter.filters[index].active = false
        console.log("set filter empty")
        return
      }
      this.filter.filters[index].value = event.target.value
      this.filter.filters[index].active = true
      console.log("set filter")
    },
    setFilterAddition(event, index) { /* comparable "filters" */
      this.filter.filters[index].compare = event.target.value
      this.filter.filters[index].active = true
      console.log("set filter addition")
    },
    loadTimes() {
      axios.get("saved/times").then(response => {
        console.log("load Run Time Ranges:")
        console.log(response.data)
        this.save.times = response.data
      })
    },
    removeTime() {
      const time = {
        id: this.save.times[this.$refs.timesLoad.value].id
      }

      axios.post("saved/removetime", time).then(response => {
        console.log("/saved/removetime");
        console.log(response.data);
      })
    },
    saveTime() {
      let timeRange = {
        fromTime: this.status.fromTime,
        toTime: this.status.toTime,
        name: this.$refs.saveTimeName.value
      }

      axios.post("saved/addtime", timeRange).then(response => {
        console.log("save Time Range:")
        console.log(response.data)
      })
    },
    setTime($event) {
      let timeRange = this.save.times[$event.target.value]

      console.log($event.target.value)
      console.log(timeRange)

      this.status.fromTime = timeRange.fromTime
      this.status.toTime = timeRange.toTime
    },
    setSettings($event) {
      let settings = this.save.parameters[$event.target.value]

      console.log($event.target.value)
      console.log(settings)

      let counter = 0
      for (let setting in this.status.from) {
        this.status.from[setting] = Math.round((settings.from[counter] + Number.EPSILON) * 100) / 100

        counter++
      }
      counter = 0
      for (let setting in this.status.step) {
        this.status.step[setting] = Math.round((settings.step[counter] + Number.EPSILON) * 100) / 100
        counter++
      }
      counter = 0
      for (let setting in this.status.to) {
        this.status.to[setting] = Math.round((settings.to[counter] + Number.EPSILON) * 100) / 100
        counter++
      }
    },
    loadSettings() {
      axios.get("saved/parameters").then(response => {
        console.log("load Run Settings:")
        console.log(response.data)
        this.save.parameters = response.data
      })
    },
    saveSettings() {
      let settings = {
        from: [],
        step: [],
        to: [],
        mechanic: "",
        name: this.$refs.saveName.value
      }
      let counter = 0
      for (let parameter of this.$refs.parameters) {
        console.log(parameter)
        settings.from.push(Object.values(this.status.from)[counter])
        counter++
      }
      counter = 0
      for (let parameter of this.$refs.parameters) {
        console.log(parameter)
        settings.step.push(Object.values(this.status.step)[counter])
        counter++
      }
      counter = 0
      for (let parameter of this.$refs.parameters) {
        console.log(parameter)
        settings.to.push(Object.values(this.status.to)[counter])
        counter++
      }

      axios.post("saved/addparameters", settings).then(response => {
        console.log("save Run Settings:")
        console.log(response.data)
      })
    },
    removeParameters() {
      const parameters = {
        id: this.save.parameters[this.$refs.settingsLoad.value].id
      }

      axios.post("saved/removeparameters", parameters).then(response => {
        console.log("/saved/removetparameters");
        console.log(response.data);
      })
    },
    loadLabels() {
      axios.get("labels").then(response => {
        this.filter.labels = response.data
      })
    },
    createLabel() {
      const label = {
        name: this.$refs.labelName.value
      }

      axios.post("addlabel", label).then(response => {
        this.filter.labels.push(response.data)
      });
    },
    removeLabel(label) {
      axios.post("removelabel", label).then(response => {
        this.filter.labels = this.filter.labels.filter(item => {
          return item.id != response.data.id
        });
      });
    },
    onDrop(event, label) {
      const runlabel = {
        run: event.dataTransfer.getData("run").id,
        label: label.id
      }

      axios.post("connectlabel", runlabel).then(response => {
        console.log("Connect label:")
        console.log(response)
      })
    },
    disconnectLabel(run, label) {
      const runlabel = {
        label: label.id,
        run: run.id
      }

      axios.post("disconnectlabel", runlabel).then(response => {
        console.log("Disconnect label:")
        console.log(response)
      });
    },
    checkAmount() {
      if (this.runStartedCount == 0) {
        // console.log(this.status.from)

        let amount = calc_amount(this.status.from, this.status.step, this.status.to)

        this.runStartedCount = 1
        this.runStarted = amount

        if (amount > this.amountLimit) {
          this.runStartedColor = "red"
        } else {
          this.runStartedColor = "green"
        }
      } else {
        if (this.runStartedColor === "green") {
          this.startRun()
        }

        this.runStartedCount = 0
        this.runStartedColor = "white"
        this.runStarted = "Start run"
      }
    },
    cancelRunStart() {
      this.runStarted = "Start run"
      this.runStartedCount = 0
      this.runStartedColor === "white"
    },
    startRun() {
      this.$refs.startrun.setAttribute("disabled", true)

      const data = { mechanic: "", fromTime: this.status.fromTime, toTime: this.status.toTime, currency: this.$refs.currency.value,
        from: Object.values(this.status.from), to: Object.values(this.status.to), step: Object.values(this.status.step) }

      console.log("Start run:");
      console.log(Object.values(this.status.from));
      console.log(Object.values(this.status.step));
      console.log(Object.values(this.status.to));

      axios.post("run", data).then(response => {
        console.log("start Run:")
        console.log(response.data.name)

        this.status.lastRunName = response.data.name
        this.status.name = response.data.name

        this.$refs.startrun.removeAttribute("disabled")
      })
    }
  }
}
</script>

<style>
.labels .selected {
  border: 0.1em solid yellow;
}

.result {
  min-width: 60em;
  width: 90%;
  margin: auto;
  margin-top: 6em;
}

.summary hr {
  margin-top: 0.2em;
  height: 0.1em;
  width: 90%;
  background-color: whitesmoke;
}

.start .start-buttons {
  margin-top: 0.3em;
}

.labels {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.labels .label {
  padding: 0;
  margin: 0;
}

.filter {
  position: fixed;
  width: 40em;
  left: calc(100% - 3em);
  border: 0.2em solid black;
  background-color: rgba(0,0,0,0.9);
  color: whitesmoke;
  z-index: 3000;
}

.filter:hover, .filter.pinned {
  left: calc(100% - 40em);
}

.start .label {
  margin-left: 0.3em;
  margin-right: 0.3em;
}

.save-time-load, .save-time-name, .save-parameters-load, .save-parameters-name {
  width: 10em;
}

.pin {
  position: absolute;
  right: 0.2em;
  bottom: 0.2em;
}

.time {
  margin-bottom: 0.8em;
}

.start:hover, .start.pinned {
  right: calc(100% - 40em);
}

.start {
  position: absolute;
  width: 40em;
  right: calc(100% - 3em);
  border: 0.2em solid black;
  background-color: rgba(0,0,0,0.9);
  color: whitesmoke;
  z-index: 1000;
}

.progress:hover, .progress.pinned {
  bottom: calc(100% - 10.5em);;
}

.progress {
  position: absolute;
  left: 10%;
  width: 80%;
  height: 10em;
  bottom: calc(100% - 5em);
  border: 0.2em double black;
  background-color: white;
  z-index: 2000;
}

.progress-lists {
  display: grid;
  grid-template-columns: auto auto;
}

.start, .progress, .filter {
  transition: all 0.5s ease-in-out;
}

.status-texts {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}

.status-big {
  /*grid-row-start: 1;*/
  /*grid-row-end: 3;*/
}

.status-small textarea, .status-big textarea {
  width: 15em;
  height: 6em;
}

.name {
  text-align: center;
}

.save-parameters {
  margin: 0.6em;
}

.save-time {
  margin: 0.6em;
}

body {
  padding-bottom: 30em;
}

.settings {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.settings li {
  margin: 0;
  padding: 0.1em;
  display: inline-block;
}

.parameters {
    text-align: left;
    list-style-type: decimal;
  }

  .parameters input {
    width: 3em;
  }

  .parameters .numbers {

  }

  .numbers:hover .helper {
    display: inline-block;
  }
  .helper {
    margin-left: 0.2em;
    display: none;
  }
  .helper input {
    margin-right: 0.1em;
  }

  .status span {
    text-align: center;
    width: 29%;
    display: inline-block;
  }

  .meta {
    border: 0.2em solid orange;
  }
  .meta span {
    padding: 0.3em;
    display: inline-block;
  }
  .averages .buysells > section {
    width: 49%;
    display: inline-block;
  }
  .averages .buys {
    /*background-color: green;*/
  }
  .averages .sells {
    /*background-color: red;*/
  }

  .averages > section {
    width: 30%;
    display: inline-block;
  }
</style>
<template>
<!--  <h1>Test</h1>-->
<!--  <img alt="Vue logo" src="./assets/logo.png">-->
<!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  <StartRun :parameters="parameters" />
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import StartRun from './components/StartRun'

export default {
  name: 'App',
  components: {
    // HelloWorld
    StartRun
  },
  data() {
    return {
      parameters: [
        {
          name: "ProzenteGefallenFuerBuyTrailingStart",
          info: "",
          range: {
            zeroIsOff: false,
            decimal: true
          }
        },
        {
          name: "ProzenteGestiegenFuerBuyTrailingEnde",
          info: "",
          range: {
            zeroIsOff: false,
            decimal: true
          }
        },
        {
          name: "ProzenteGestiegenFuerSellTrailingStart",
          info: "",
          range: {
            zeroIsOff: false,
            decimal: true
          }
        },
        {
          name: "ProzenteGefallenFuerSellTrailingEnde",
          info: "",
          range: {
            zeroIsOff: false,
            decimal: true
          }
        },
        {
          name: "BuyOrderAuszeitAktiv",
          info: "Schaltet die BuyOrderAuszeit Mechanik an." +
              "Wenn die Mechanik in einem Kauf feststeckt (gemacht hat), aber der Kurs nur fällt wird der SellTrailingStart übergangen und direkt zum SellTrailingEnde gesprungen. D.h.: Es wird nicht auf einen erst steigenden SellTrailingStart gewartet, sondern beim nächst weiter um SellTrailingEnde fallenden Wert verkauft. " +
              "\"Der Kurs nur fällt\" ist festgelegt als: betrachte den Wert des aktuellen (feststeckenden) Kaufes und schaue ob der aktuelle Kurspunkt einem Wertverlust von dem gemeinsamen Profit der x letzten Käufe/Verkäufe um das y-Fache entspricht. " +
              "Hat eigentlich nichts mit der Zeit zu tun. Entweder alle drei Felder 0, oder alle drei Felder 1.",
          range: {
            zeroIsOff: false,
            decimal: false
          }
        },
        {
          name: "BuyOrderAuszeitUmAnteilZumGewinnGefallen",
          info: "Ist der y-Wert der eben beschriebenen Mechanik. Beispiel: ist der letzte Profit um das 0.5-Fache gefallen.",
          range: {
            zeroIsOff: false,
            decimal: true
          }
        },
        {
          name: "BuyOrderAuszeitAnteilDerLetztenZuVerwendenenBuyOrders",
          info: "Der Name ist falsch: es sollte \"Anzahl\" anstatt \"Anteil\" heißen. Ist der x-Wert der eben beschriebenen Mechanik. Wieviele der letzten Käufe-Verkäufe Pärchen werden in den \"letzten gemeinsamen Profit\" mit einbezogen." +
              "Beispiel: die letzten 5 Käufe-Verkäufe Profite werden werden mit dem y-Fachen des aktuellen Kurswerts zum letzten Kauf verglichen.",
          range: {
            zeroIsOff: false,
            decimal: false
          }
        },
        {
          name: "BuyOrderAuszeitVarianzAnzahl",
          info: "Ist eine Untermechanik der BuyOrderAuszeit, welche den direkten Sprung zum SellTrailingEnde unterbindet, falls in den letzten Zeiträumen der durchschnitt der Kurswerte gestiegen ist.",
          range: {
            zeroIsOff: true,
            decimal: false
          }
        },
        {
          name: "BuyOrderAuszeitZeitMehfachVergangenZumLetztenDurschnitt",
          info: "Wird überhaupt garnicht mehr in der Mechanik verwendet. Deswegen hier keine Kombinationen erzeugen. Ändert sowieso nichts.",
          range: {
            zeroIsOff: false,
            decimal: true
          }
        },
        {
          name: "VarianzAktiv",
          info: "Stellt die BuyTrailingStart, BuyTrailingEnde, sowie SellTrailingStart und SellTrailingEnde automatisch anhand vorheriger Kurs Zeitabschnitte ein. " +
              "Hierdurch werden Werte im BuyTrailingStart, BuyTrailingEnde, sowie SellTrailingStart und SellTrailingEnde hinfällig, weil sie automatsich verändert werden. Bei Aktivierung keine Kombinationen bei den ersten 4 Parametern (Buy-Sell-Trailing-Start-und-Ende) erzeugen lassen. Diese werden sowieso in jeder Kombination überschrieben." +
              "Entweder alle drei Felder 0, oder alle drei Felder 1.",
          range: {
            zeroIsOff: true,
            decimal: false
          }
        },
        {
          name: "VarianzZeitspanneInStunden",
          info: "Legt fest wie Groß die vorherigen erwähnten Zeitabschnitte sind. In Stunden.",
          range: {
            zeroIsOff: false,
            decimal: false
          }
        },
        {
          name: "VarianzSchwankungsProzenteFaktor",
          info: "Zu welchem Faktor die Wert-Schwankungen in den vorherigen Zeitabschnitten den BuyTrailingStart beeinflussen.",
          range: {
            zeroIsOff: false,
            decimal: true
          }
        },
        {
          name: "VarianzSchwankungsProzenteFaktorBuyTrailingEnde",
          info: "Zu welchem Faktor die Wert-Schwankungen in den vorherigen Zeitabschnitten das BuyTrailingEnde beeinflussen.",
          range: {
            zeroIsOff: false,
            decimal: true
          }
        },
        {
          name: "VarianzSchwankgunsPorzenteFaktorSellTrailingStart",
          info: "Zu welchem Faktor die Wert-Schwankungen in den vorherigen Zeitabschnitten den SellTrailingStart beeinflussen.",
          range: {
            zeroIsOff: false,
            decimal: true
          }
        },
        {
          name: "VarianzSchwankungsProzenteFaktorSellTrailingEnde",
          info: "Zu welchem Faktor die Wert-Schwankungen in den vorherigen Zeitabschnitten das SellTrailingEnde beeinflussen.",
          range: {
            zeroIsOff: false,
            decimal: true
          }
        },
        {
          name: "VarianzSchwankungsAnzahl",
          info: "Wieviele der letzten Zeitabschnitte in die Veränderung der Werte miteinbezogen werden. Beispiel: die letzten 5 der 12-Stunden Zeiträume.",
          range: {
            zeroIsOff: false,
            decimal: false
          }
        },
        {
          name: "VarianzFeintuningsAnzahl",
          info: "Beieinflusst ähnlich wie die VarianzSchwankungsAnzahl auch über die letzten Zeitabschnitte die Veränderung der Werte. Quasi zusätzlich nochmal. Ich erkläre das später nochmal.",
          range: {
            zeroIsOff: true,
            decimal: false
          }
        },
        {
          name: "Internal Usage",
          info: "Kannst du nicht benutzen. Finger weg. Erhöht nur die Menge ohne Nutzen.",
          range: {
            zeroIsOff: false,
            decimal: false
          }
        },
        {
          name: "StopLoss",
          info: "Bei wieviel Prozent gefallenen Kurses im Vergleich zum letzten Buy direkt verkauft wird.",
          range: {
            zeroIsOff: true,
            decimal: true
          }
        },
      ]
    }
  }
}
</script>

<style>
span {
  margin-right: 0.2em;
}
input[type=button] {
  cursor: pointer;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<template>
  <h1>{{run.original}}</h1>
  <section class="results">
    <section class="small" @click="progressExtend()">
      <section class="queue-run-done">
        <span v-if="kind == 'queued'">1.</span>
<!--        <span class="name">{{run.name != "" ? this.limitCharacters(run.name, 16) : this.limitCharacters(run.original, 16)}}</span>-->
        <span v-if="kind == 'done'">Avg: 2,54 %</span>
        <section class="run-progress" v-if="kind == 'run'">{{run.progress.PerformCount}}</section>
      </section>
      <section class="piraten">
<!--        <span>Currency: {{run.currency}}</span>-->
<!--        <span>Points: {{run.pointsCount}}</span>-->
<!--        <span>Amount: {{run.amount}}</span>-->
      </section>
      <section class="grab" draggable="true" @dragstart="startDrag($event)"></section>
    </section>

    <template v-if="kind == 'done'"  >
<!--      <input class="delete-run" @click="deleteRun(run.original)" type="button" value="Delete run">-->
      <section  class="meta-big" v-bind:class="{extended: (view.extended > 0)}">
        <header class="label">Meta:</header>
        <section>
          <span class="label">Name:</span>
          <span>Alias:</span><input class="alias" @change="changeRunName(run.original, $event)" type="text">
        </section>
        <section>Original: {{run.original}}</section>
        <section class="short">
          <span class="currency">{{run.currency}}</span>
          <span class="fromTime"><span class="label">Von:</span> {{run.fromTime}}</span>
          <span class="toTime"><span class="label">Bis:</span> {{run.toTime}} </span>
<!--          <span class="pointscount"><span class="label">Points:</span> {{run.pointsCount}} </span>-->
        </section>
<!--        <section class="amount"><span class="label">Amount:</span> {{run.amount}}</section>-->
        <section class="runSettings">
          <section class="settings-container">
            <header><span class="label"> From: </span></header>
            <ul class="settings fromSettings">
              <li :key="index" v-for="(setting, index) in run.from">{{setting}}</li>
            </ul>
            <section class="inspector">
              <header><span class="label"> From: </span></header>
              <ul>
                <li :key="key" v-for="(setting, key) in run.from">{{parameters[key]}}: {{setting}}</li>
              </ul>
            </section>
          </section>
          <section  class="settings-container">
            <header><span class="label"> Step: </span></header>
            <ul class="settings settingsStep">
              <li :key="index" v-for="(setting, index) in run.step">{{setting}}</li>
            </ul>
            <section class="inspector">
              <header><span class="label"> Step: </span></header>
              <ul>
                <li :key="key" v-for="(setting, key) in run.step">{{parameters[key]}}: {{setting}}</li>
              </ul>
            </section>
          </section>
          <section  class="settings-container">
            <header><span class="label"> To: </span></header>
            <ul class="settings toSettings">
              <li :key="index" v-for="(setting, index) in run.to">{{setting}}</li>
            </ul>
            <section class="inspector">
              <header><span class="label"> To: </span></header>
              <ul>
                <li :key="key" v-for="(setting, key) in run.to">{{parameters[key]}}: {{setting}}</li>
              </ul>
            </section>
          </section>
        </section>
      </section>
      <section class="result-big" v-bind:class="{extended: (view.extended > 1)}">
        <section  class="averages-big">
          <section class="top">
            <header> <span class="label"> Top 30 % Average: </span></header>
            <section class="settings-container">
              <ul class="settings">
                <li :key="index" v-for="(setting, index) in run.results.topAverage.parameter">{{setting}}</li>
              </ul>
              <section class="inspector">
                <header> <span class="label"> Top 30 % Average: </span></header>
                <ul>
                  <li :key="key" v-for="(setting, key) in run.results.topAverage.parameter">{{parameters[key]}}: {{setting}}</li>
                </ul>
              </section>
            </section>
            <section v-bind:class="'profit '+ colorBackground(run.results.topAverage.value)">
              {{run.results.topAverage.value * 100}}%
            </section>
            <section class="buysells">
              <section class="buys">
                {{Math.round(run.results.topAverage.buysCount * 100) / 100}}
              </section>
              <section class="sells">
                {{Math.round(run.results.topAverage.sellsCount * 100) / 100}}
              </section>
              <section class="over90">
                {{Math.round(run.results.topAverage.over90 * 100) / 100}}
              </section>
            </section>
          </section>
          <section class="average">
            <header> <span class="label"> Complete Average: </span></header>
            <section class="settings-container">
              <ul class="settings">
                <li :key="index" v-for="(setting, index) in run.results.average.parameter">{{setting}}</li>
              </ul>
              <section class="inspector">
                <header> <span class="label"> Complete Average: </span></header>
                <ul>
                  <li :key="key" v-for="(setting, key) in run.results.average.parameter">{{parameters[key]}}: {{setting}}</li>
                </ul>
              </section>
            </section>
            <section v-bind:class="'profit '+ colorBackground(run.results.average.value)">
              {{run.results.average.value * 100}} %
            </section>
            <section class="buysells">
              <section class="buys">
                {{Math.round(run.results.average.buysCount * 100) / 100}}
              </section>
              <section class="sells">
                {{Math.round(run.results.average.sellsCount * 100) / 100}}
              </section>
              <section class="over90">
                {{Math.round(run.results.average.over90 * 100) / 100}}
              </section>
            </section>
          </section>
          <section class="bottom">
            <header> <span class="label"> Bottom 30 % Average:</span></header>
            <section class="settings-container">
              <ul class="settings">
                <li :key="index" v-for="(setting, index) in run.results.bottomAverage.parameter">{{setting}}</li>
              </ul>
              <section class="inspector">
                <header> <span class="label"> Bottom 30 % Average:</span></header>
                <ul>
                  <li :key="key" v-for="(setting, key) in run.results.bottomAverage.parameter">{{parameters[key]}}: {{setting}}</li>
                </ul>
              </section>
            </section>
            <section v-bind:class="'profit '+ colorBackground(run.results.bottomAverage.value)">
              {{run.results.bottomAverage.value * 100}} %
            </section>
            <section class="buysells">
              <section class="buys">
                {{Math.round(run.results.bottomAverage.buysCount * 100) / 100}}
              </section>
              <section class="sells">
                {{Math.round(run.results.bottomAverage.sellsCount * 100) / 100}}
              </section>
              <section class="over90">
                {{Math.round(run.results.bottomAverage.over90 * 100) / 100}}
              </section>
            </section>
          </section>
        </section>
        <section>
          <input @click="view.topbottom30 ? view.topbottom30 = false : view.topbottom30 = true" v-bind:value="view.topbottom30 ? 'Hide TopBottom30' : 'Show TopBottom30'" type="button" >
        </section>
        <section class="topbottom30" v-bind:class="{extendedtopbottom30: view.topbottom30}">
          <section class="top30units">
            <header> <span class="label"> Top 30 Settings: </span> </header>
            <ul class="top30">
              <li :key="value" v-for="value in run.results.top30">
                <section class="settings-container">
                  <ul class="settings"><li :key="x" v-for="x in value.parameter">{{Math.round(x * 100) / 100}}</li></ul>
                  <section class="inspector">
                    <ul>
                      <li :key="key" v-for="(setting, key) in value.parameter">{{parameters[key]}}: {{Math.round(setting * 100) / 100}}</li>
                    </ul>
                  </section>
                </section>
                <div>
                  <span v-bind:class="'profit '+ colorBackground(value.value)">{{(Math.round(value.value * 10000) / 10000) * 100}}</span>
                  <span class="buys">{{parseInt(value.buysCount)}}</span>
                  <span class="sells">{{parseInt(value.sellsCount)}}</span>
                  <span class="over90">{{parseInt(value.over90)}}</span>
                </div>
              </li>
            </ul>
          </section>
          <section class="bottom30units">
            <header> <span class="label">Bottom 30 Settings:</span> </header>
            <ul class="bottom30">
              <li :key="value" v-for="value in run.results.bottom30">
                <section class="settings-container">
                  <ul class="settings"><li :key="x" v-for="x in value.parameter">{{Math.round(x * 100) / 100}}</li></ul>
                  <section class="inspector">
                    <ul>
                      <li :key="key" v-for="(setting, key) in value.parameter">{{parameters[key]}}: {{Math.round(setting * 100) / 100}}}</li>
                    </ul>
                  </section>
                </section>
                <div>
                  <span v-bind:class="'profit '+ colorBackground(value.value)">{{(Math.round(value.value * 10000) / 10000) * 100}}</span>
                  <span class="buys">{{parseInt(value.buysCount)}}</span>
                  <span class="sells">{{parseInt(value.sellsCount)}}</span>
                  <span class="over90">{{parseInt(value.over90)}}</span>
                </div>
              </li>
            </ul>
          </section>
        </section>
      </section>
    </template>

  </section>
</template>

<script>
import axios from 'axios';

// function settings(settings) {
//   let float_settings = settings.split(", ").map(char => parseFloat(char))
//
//   return float_settings
// }
//
// function settingsaverages(settings) {
//   let float_settings = settings.split(" ").map(char => parseFloat(char))
//
//   return float_settings
// }

export default {
  name: "ResultRun",
  // watch: {
  //   run(newValue) {
  //     this.getRun(newValue);
  //
  //     console.log("Watch result run")
  //     console.log(newValue)
  //   }
  // },
  mounted() {
    // this.getRun();
  },
  props: {
    run: Object,
    kind: String,
    parameters: [String],
  },
  data() {
    return {
      // run: {
      //   meta: {
      //     name: {
      //       alias: "",
      //       original: "",
      //     },
      //     currency: "",
      //     fromTime: "",
      //     toTime: "",
      //     pointsCount: 0,
      //     amount: 0,
      //     fromSettings: [],
      //     toSettings: [],
      //     settingsStep: [],
      //   },
      //   top: {
      //     buys: 0,
      //     sells: 0,
      //     profit: 0,
      //     settings: [],
      //   },
      //   average: {
      //     buys: 0,
      //     sells: 0,
      //     profit: 0,
      //     settings: [],
      //   },
      //   bottom: {
      //     buys: 0,
      //     sells: 0,
      //     profit: 0,
      //     settings: [],
      //   },
      //   top30: {
      //     data: []
      //   },
      //   bottom30: {
      //     data: []
      //   },
      // },
      status: {
        status: "",
        perform: {
          speed: "",
          count: "",
        },
        sort: {
          sorting: "",
          summary: "",
        },
        all: {
          complete: ""
        }
      },
      view: {
        extended: 0,
        topbottom30: false,
      }
    }
  },
  methods: {
    progressExtend() {
      this.view.extended = (this.view.extended + 1) % 3
      // this.view.extended = 2

      console.log("Hä?")
    },
    startDrag(event) {
      event.dataTransfer.dropEffect = "link"
      event.dataTransfer.effectAllowed = "link"
      event.dataTransfer.setData("run", this.run )
    },
    limitCharacters(value, length) {
      if (value.length > length) {
        return value.substring(0, length) + "..."
      } else {
        return value
      }
    },
    colorBackground(value) {
      if (value > 0) {
        return 'green'
      } else if (value == 0) {
        return 'yellow'
      } else {
        return 'red'
      }
    },
    changeRunName(runName, $event) {
      console.log("change Run Name:")
      console.log(runName)

      axios.get("change/"+encodeURI(runName)+"/"+encodeURI($event.target.value)).then(response => {
        console.log("change Run Name response:")
        console.log(response)

        // eslint-disable-next-line vue/no-mutating-props
        this.run.name = response.data.new
      })
    },
    deleteRun(runName) {
      console.log("delte Run:")
      console.log(runName)

      axios.get("delete/"+encodeURI(runName)).then(response => {
        console.log("delte Run response:")
        console.log(response)
      })
    },
    // getRun() {
    //   console.log("TEste")
    //
    //   axios.get("run/"+encodeURI(this.run)+"/"+ this.kind).then(response => {
    //     console.log(response)
    //
    //     this.run.name = response.data.name;
    //     this.run.original = response.data.original;
    //
    //     this.run.currency = response.data.currency;
    //     this.run.fromTime = response.data.fromTime;
    //     this.run.toTime = response.data.toTime;
    //     this.run.pointsCount = response.data.points;
    //     this.run.amount = response.data.amount;
    //     this.run.fromSettings = settings(response.data.from);
    //     this.run.toSettings = settings(response.data.to);
    //     this.run.settingsStep = settings(response.data.step);
    //
    //     if (this.kind == "done") {
    //       this.run.top.buys = response.data.top.buys
    //       this.run.top.sells = response.data.top.sells
    //       this.run.top.profit = response.data.top.profit
    //       this.run.top.settings = settingsaverages(response.data.top.settings)
    //
    //       this.run.average.buys = response.data.average.buys
    //       this.run.average.sells = response.data.average.sells
    //       this.run.average.profit = response.data.average.profit
    //       this.run.average.settings = settingsaverages(response.data.average.settings)
    //
    //       this.run.bottom.buys = response.data.bottom.buys
    //       this.run.bottom.sells = response.data.bottom.sells
    //       this.run.bottom.profit = response.data.bottom.profit
    //       this.run.bottom.settings = settingsaverages(response.data.bottom.settings)
    //
    //       this.run.top30.data = response.data.top30
    //       this.run.bottom30.data = response.data.bottom30
    //
    //       console.log("TEste")
    //       console.log(this.run.bottom.settings)
    //     }
    //   });
    // },
  }
}
</script>

<style scoped>
  .grab {
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 3em;
    background-color: black;
  }

  .results {
    margin-bottom: 0.2em;
    border: 0.1em solid saddlebrown;
  }

  .settings-container {
    position: relative;
  }

  .settings-container:hover .inspector {
    display: block;
  }

  .inspector {
    padding: 0.5em;
    border: 0.1em solid white;
    background-color: black;
    color: white;

    position: absolute;
    width: 30em;
    top: 100%;
    left: 0;

    display: none;
    z-index: 100;
  }

  .inspector ul {
    margin-top: 0;
    text-align: left;
    list-style-type: decimal;
  }

  .meta-big, .result-big {
    display: none;
  }

  .extended {
    display: block;
  }

  .topbottom30 {
    display: none;
  }

  .extendedtopbottom30 {
    display: grid;
    grid-template-columns: auto auto;
  }

  .runSettings {
    display: grid;
    grid-template-columns: auto auto auto;
  }

  .averages-big {
    display: grid;
    grid-template-columns: auto auto auto;
  }

  .small {
    position: relative;
    cursor: pointer;
    display: grid;
    grid-template-columns: auto auto;
  }

  .result .small {
    background-color: darkgray;
  }

  .small .run-progress {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
  }

  .profit, .buys, .sells {
    margin-right: 0.6em;
  }

  .label {
    font-weight: bold;
  }

  .red {
    background-color: red;
  }
  .green {
    background-color: green;
  }
  .yellow {
    background-color: yellow;
  }

  .results {
    position: relative;
    border: 0.3em solid saddlebrown;
  }

  .delete-run {
    position: absolute;
    right: 0;
    top: 0;
  }

  .meta-big {
    position: relative;
  }

  .averages-big .top, .averages-big .average, .averages-big .bottom {
    padding: 0.1em;
    border: 0.1em solid yellow;
  }

  .top30 {
    list-style-type: decimal;
  }

  .bottom30 {
    list-style-type: none;
    counter-reset: a 30;
    position: relative;
  }
  .bottom30 > li::before {
    position: absolute;
    left: 0;
    content: counter(a)'.:';
    counter-increment: a -1;
  }
</style>
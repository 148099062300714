<template>
  <span v-if="state == 1">Sure?</span><input type="button" v-bind:value="currentText" @click="progress"><input v-if="state == 1" @click="cancel" type="button" value="Cancel">
</template>

<script>
export default {
  name: "AreYouSure",
  props: {
    text: String,
    function: Function,
  },
  // mounted() {
  //   this.currentText = this.text
  // },
  data() {
    return {
      state: 0,
      currentText: this.text,
    }
  },
  methods: {
    progress() {
      if (this.state == 0) {
        this.state = 1
        this.currentText = "Yes"
      } else {
        this.state = 0
        this.currentText = this.text
        this.function()
      }
    },
    cancel() {
      this.state = 0
      this.currentText = this.text
    }
  }
}
</script>

<style scoped>
  span, input {
    margin-left: 0.2em;
    margin-right: 0.2em;
  }
</style>